<style lang="less" scoped>
.pfm-month-details-router {
  background-color: #f8f8f9;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <router-view class="pfm-month-details-router" />
</template>

<script>

export default {
  name: 'CalculateMonthDetails'
}
</script>